import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { globalInfoStore } from "../../stores/GlobalInfoStore";
import axios from "axios";
import { isPlatform } from "@ionic/react";
import { Capacitor, Plugins, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
import AppDownload from "../../components/organisms/AppDownload";
import { useLocation } from "react-router-dom";

const { Filesystem } = Plugins;

// import { pdfjs } from 'react-pdf';

// import passImg01 from "../../resources/img/passImg01.png";  //24 card
// import passImg02 from "../../resources/img/passImg02.png";  //48 card
// import passImg03 from "../../resources/img/passImg03.png";  //72 card
// import passImg04 from "../../resources/img/passImg04.png";  //24 mobile
// import passImg05 from "../../resources/img/passImg05.png";  //48 mobile
// declare module '*.pdf' {
//   const src: string;
//   export default src;
// }
const arexKor = "https://discoverseoulpass-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/m/pdf/arex/arex-kor.pdf";
const arexEng = "https://discoverseoulpass-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/m/pdf/arex/arex-eng.pdf";
const arexJpn = "https://discoverseoulpass-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/m/pdf/arex/arex-jpn.pdf";
const arexChn = "https://discoverseoulpass-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/m/pdf/arex/arex-chn.pdf";

const TransportationArex = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    
    useEffect(() => {

    }, [globalInfoStore.currentLangType])

    const goToPdf = (pdfUrl:string) => {
      window.open(pdfUrl, "_blank")
    }

    // const IsShowApk = () => {
    //   // console.log(JSON.stringify(globalInfoStore.currentLangType.i18Str));
    //   if (globalInfoStore.currentLangType.i18Str == 'zhChs'){ //중문 간제 제거
    //     return (<li className="file"><a href="https://play.google.com/store/apps/details?id=com.travolution.discover"><em>APK File download</em></a></li>)
    //   }
    //   return (null)
    // }

    const downloadArexGuide = () => {

        if (globalInfoStore.currentLangType.i18Str == "ko"){
            return (<a href="#" onClick={(e)=>goToPdf(arexKor)} className="btn_automatic" >{t("how_arex.ticket_machine_link")}</a>)
        }
        if (globalInfoStore.currentLangType.i18Str == "zhChs" ||globalInfoStore.currentLangType.i18Str == "zhCht"){
            return (<a href="#" onClick={(e)=>goToPdf(arexChn)} className="btn_automatic" >{t("how_arex.ticket_machine_link")}</a>)
        }
        if (globalInfoStore.currentLangType.i18Str == "ja"){
            return (<a href="#" onClick={(e)=>goToPdf(arexJpn)} className="btn_automatic" >{t("how_arex.ticket_machine_link")}</a>)
        }
        return (<a href="#" onClick={(e)=>goToPdf(arexEng)} className="btn_automatic" >{t("how_arex.ticket_machine_link")}</a>)
        // if (globalInfoStore.currentLangType.i18Str == "ko"){
        //     return (<a href="#" onClick={(e)=>downloadPdf(arexKor, "arexKor")} target="_blank" className="btn_automatic" download>{t("how_arex.ticket_machine_link")}</a>)
        // }
        // if (globalInfoStore.currentLangType.i18Str == "zhChs" ||globalInfoStore.currentLangType.i18Str == "zhCht"){
        //     return (<a href="#" onClick={(e)=>downloadPdf(arexChn, "arexChn")} target="_blank" className="btn_automatic" download>{t("how_arex.ticket_machine_link")}</a>)
        // }
        // if (globalInfoStore.currentLangType.i18Str == "ja"){
        //     return (<a href="#" onClick={(e)=>downloadPdf(arexJpn, "arexJpn")} target="_blank" className="btn_automatic" download>{t("how_arex.ticket_machine_link")}</a>)
        // }
        // return (<a href="#" onClick={(e)=>downloadPdf(arexEng, "arexEng")} target="_blank" className="btn_automatic" download>{t("how_arex.ticket_machine_link")}</a>)

    }
    
    return (
        <div className="wrap_use">
            <p className="use_tit">{t("how_arex.title")}</p>
            <div className="exp_box_nomargin" dangerouslySetInnerHTML={{__html:t("how_arex.desc")}}>
            </div>
            <a href="https://www.discoverseoulpass.com/app/spot/index/view/41" target="_blank" className="btn">{t("how_arex.desc_link")}</a>

            <p className="use_tit">{t("how_arex.sub_title")}</p>
            <div className="step arex">
                <dl className="step01">
                    <dt>STEP 1</dt>
                    <dd>{t("how_arex.step1_desc2")}</dd>
                </dl>
                <dl className="step02">
                    <dt>STEP 2</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_arex.step2_desc2")}}></dd>
                </dl>
                <dl className="step03">
                    <dt>STEP 3</dt>
                    <dd>{t("how_arex.step3_desc2")}</dd>
                </dl>
            </div>
            

            <dl className="box_step">
                <dt dangerouslySetInnerHTML={{__html:t("how_arex.location_title")}}></dt>
                <dd dangerouslySetInnerHTML={{__html:t("how_arex.location1")}}>

                </dd>
                <dd dangerouslySetInnerHTML={{__html:t("how_arex.location2")}}>

                </dd>
                <dd dangerouslySetInnerHTML={{__html:t("how_arex.location3")}}>
                    
                </dd>
            </dl>

            <p className="use_tit">{t("how_arex.step_title")}</p>
            <div className="step mobile">
                <dl className="step01">
                    <dt>STEP 1</dt>
                    <dd>{t("how_arex.step1_desc")}</dd>
                </dl>
                {/* <dl className="step02">
                    <dt>STEP 2</dt>
                    <dd dangerouslySetInnerHTML={{__html:t("how_arex.step2_desc")}}></dd>
                </dl> */}
                <dl className="step02">
                    <dt>STEP 2</dt>
                    <dd>{t("how_arex.step3_desc")}</dd>
                </dl>
            </div>

            {isPlatform("capacitor") || location.pathname.startsWith('/appnv') ? null
            :
            <div className="exp_box use03">
                <span>{t("how_arex.app_download")}</span>
                {/* <ul className="apk">
                    <li className="apple"><a href="https://apps.apple.com/kr/app/id1131125136"><b>App Store</b> download</a></li>
                    <li className="google"><a href="https://play.google.com/store/apps/details?id=com.travolution.discover"><b>Google Play</b> download</a></li>
                    <IsShowApk></IsShowApk>
                </ul> */}
                <AppDownload type="bold"></AppDownload>
            </div>
            }

        </div>
    );
};

export default TransportationArex;